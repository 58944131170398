<template>
  <li>
    <a
      href="#"
      class="block hover:bg-gray-50"
    >
      <div class="px-4 py-4 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-sm font-medium text-indigo-600 truncate">
            <fa-icon
              v-if="isUploading"
              icon="spinner"
              spin
              class="mr-1 fa-fw"
            />
            {{ item.file.name }}
          </p>
        </div>
        <div class="items-center mt-2 space-x-2 sm:flex sm:justify-between">
          <div class="flex-1 sm:flex">
            <div class="w-full h-1 bg-gray-300">
              <div
                class="h-1 text-xs leading-none text-center text-white rounded"
                :style="percentStyle"
                :class="progressBarClass"
              />
            </div>
          </div>
          <div class="flex items-center mt-2 text-sm text-gray-500 sm:mt-0">
            <template v-if="isAborted">
              <button
                class="float-right px-2 py-1 text-gray-600 rounded-full cursor-pointer dark:gray-200 font-sm focus:outline-none hover:bg-gray-400"
                @click.prevent="onClearAbortedUpload"
              >
                <fa-icon
                  icon="xmark"
                  class="float-right"
                />
              </button>
            </template>

            <template v-if="isUploaded">
              <button
                class="float-right px-2 py-1 text-gray-600 rounded-full cursor-pointer dark:gray-200 font-sm focus:outline-none hover:bg-gray-400"
                @click.prevent="onClear"
              >
                <fa-icon
                  icon="check"
                  class
                />
              </button>
            </template>

            <template v-if="isUploading">
              <button
                class="float-right px-2 py-1 text-gray-600 rounded-full cursor-pointer dark:gray-200 font-sm focus:outline-none hover:bg-gray-400"
                @click.prevent="onCancelUpload"
              >
                <fa-icon
                  icon="xmark"
                  class="float-right"
                />
              </button>
            </template>
          </div>
        </div>
      </div>
    </a>
  </li>
</template>
<script>
export default {
  name: 'AssetExplorerTrayListItem',

  props: {
    item: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      selfClearDelay: 2500 // 2.5 secs
    }
  },

  computed: {
    isAborted: function () {
      return this.status === 'aborted'
    },

    isFailed: function () {
      return this.status === 'error'
    },

    isHealthy: function () {
      return !this.isAborted && !this.isFailed
    },

    isUploaded: function () {
      return this.isHealthy && this.percentUploaded >= 100
    },

    isUploading: function () {
      return this.isHealthy && this.percentUploaded < 100
    },

    progressBarClass: function () {
      if (this.isHealthy) {
        if (this.isUploading) return 'bg-blue-500'
        else return 'bg-green-500'
      } else {
        return 'bg-red-500'
      }
    },

    percentUploaded: function () {
      return this.isHealthy ? Math.round(this.item.progress * 100) : 100
    },

    percentStyle: function () {
      return 'width : ' + this.percentUploaded + '%'
    },

    status: function () {
      return this.item.uploadState
    }
  },

  watch: {

    status: function (status) {
      if (this.isAborted) {
        this.$emit('abortUpload', this.item)
        return
      }

      if (this.isFailed) {
        this.$emit('uploadError', this.item)
      }

      if (this.isUploaded) {
        setTimeout(() => {
          this.$emit('uploadComplete', this.item)
        }, this.selfClearDelay)
      }
    }
  },

  methods: {
    // @todo This can't be right. We're calling a method on a passed-in property
    onCancelUpload: function () {
      this.item.abortUpload()
    },

    onClearAbortedUpload: function () {
      this.item.clearAfterError()
    },

    onClearFailedUpload: function () {
      this.item.clearAfterError()
    },

    onClear: function () {
      this.$emit('clear', this.item)
    }
  }
}

</script>
